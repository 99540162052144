const ENDPOINT = "https://app-apis.foodshadowservicetoothers.com/api/general";

const postRequest = async (url, body = {}) => {
  try {
    const response = await fetch(`${ENDPOINT}${url}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return response.json();
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getRequest = async (url) => {
  try {
    return await fetch(`${ENDPOINT}${url}`, {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) return data;
        else return false;
      });
  } catch (error) {
    console.log(error);
    return false;
  }
};

const api = (function () {
  const get = (url) => getRequest(url);
  const post = (url, body) => postRequest(url, body);

  return { get, post };
})();

export default api;
