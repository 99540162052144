import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import api from "./services/api";
import AppContext from "./context";
import "./scss/App.scss";

const Footer = lazy(() => import("./components/Footer"));
const DownloadApp = lazy(() => import("./components/DownloadApp"));
const Features = lazy(() => import("./pages/features"));
const AboutUs = lazy(() => import("./pages/about"));
const HomePage = lazy(() => import("./pages/home"));
const Privacy = lazy(() => import("./pages/privacyPolicy"));
const Terms = lazy(() => import("./pages/terms"));

const App = () => {
  const dataFetchedRef = useRef(false);
  const [details, setDetails] = useState({});
  const getList = async () => {
    const { data } = await api.get("/generalinfo?client=web");
    if (data) setDetails(data);
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getList();
  }, []);
  return (
    <>
      <AppContext.Provider value={details}>
        <Suspense
          fallback={
            <div
              style={{ height: "100vh" }}
              className="container  d-flex align-items-center justify-content-center"
            >
              <div className="bouncing-loader"></div>
            </div>
          }
        >
          <Routes>
            <Route exact path="/" element={<HomePage />}>
              <Route
                path="*"
                element={() => (
                  <div className="container text-dark">No Match</div>
                )}
              />
            </Route>
            <Route path="/about" element={<AboutUs />} />
            <Route path="/features" element={<Features />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
          <Container fluid className="outer-container">
            <DownloadApp wrapperClass="section section-download-app"></DownloadApp>
            <Footer></Footer>
          </Container>
        </Suspense>
      </AppContext.Provider>
    </>
  );
};
export default App;